import Head from 'next/head';

const HeadMeta = ({data, api}) => {
  if(!data) return null

  let title = data?.title;
  let description = data?.description;
  let keywords = data?.keywords;
  if(title && title!=''){
    let arr_title = title?.split('&#');
    if(arr_title.length>1){
      arr_title = arr_title[1].split(';');
      if(arr_title.length){
        title = title.replace('&#'+arr_title[0]+';', String?.fromCodePoint(arr_title[0]));
      }
    }
  }
  if(description && description!=''){
    let arr_description = description?.split('&#');
    if(arr_description.length>1){
      arr_description = arr_description[1].split(';');
      if(arr_description.length){
        description = description.replace('&#'+arr_description[0]+';', String?.fromCodePoint(arr_description[0]));
      }
    }
  }
  if(keywords && keywords!=''){
    let arr_keywords = keywords?.split('&#');
    if(arr_keywords.length>1){
      arr_keywords = arr_keywords[1].split(';');
      if(arr_keywords.length){
        keywords = keywords.replace('&#'+arr_keywords[0]+';', String?.fromCodePoint(arr_keywords[0]));
      }
    }
  }

  return (
    <Head>
      {data.title && <title>{title}</title>}
      {data.description && <meta name="description" content={description} />}
{/*
      {data.keywords && <meta name="Keywords" content={Array.isArray(data.keywords) ? data.keywords.join(', ') : data.keywords} />}
*/}
      {data.noindex && <meta name="robots" content="noindex, follow"/>}
      {data.canonical && <link rel="canonical" href={data.canonical}/>}

      {data.title && <meta property="og:title" content={title}/>}
      {data.description && <meta property="og:description" content={description}/>}
      {data.canonical && <meta property="og:url" content={data.canonical} />}

    </Head>
  )
}

export default HeadMeta;
